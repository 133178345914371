<template>
  <div style="margin: 20px">
    <div class="flex items-center" style="align-items: flex-end;">

      <div class="btn-add-entry" :style="{ backgroundColor: enableCreateIndividualApp ? '#ff6450' : '#999999' }"
        @click="createEntry('个人', null)"> Create an Individual <br>Application </div>
      <div class="btn-add-entry" :style="{ backgroundColor: enableCreateIndividualApp ? '#ff6450' : '#999999' }"
        style="margin-left: 15px;" @click="createEntry('团队', null)"> Create a Team <br>Application
      </div>
      <div class="btn-add-entry" v-if="checkEnableShowFeatureAwardButton()"
        :style="{ backgroundColor: enableCreateIndividualApp ? '#ff6450' : '#999999' }" style="margin-left: 15px;"
        @click="showFeatureAwardListView = true"> Feature Award <br>Application
      </div>
      <div style="margin-left: 15px;color: #333;font-size: 14px;">
        <p>Please note:</p>
        <!-- <p v-html="str_note_1"></p> -->
        <p v-html="str_note_2"></p>
      </div>
    </div>
    <div v-if="showFeatureAwardListView == false && dataList">
      <div v-for="(item, index) in dataList" :key="index">
        <ActivityEntry :info="item" :index="index" @standards="showStandardsDialog(item)" @edit="editEntry(item)"
         @submitDate="doSubmitReviewEntryDate(item)"
          @remove="removeEntry(item)" @quitEntry="handleQuitEntry" />
      </div>
    </div>
    <FeatureAwards v-if="showFeatureAwardListView == true" style="margin-top: 20px;"
      @handleApplyFeatureAward="handleApplyFeatureAward"></FeatureAwards>

    <el-dialog :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <div style="margin-top: -30px;width: 100%;padding: 0px 30px;">
        <p v-if="checkIsFeatureAwardEntry()"
          style="text-align: center;color: #ff6450;font-size: 28px;font-weight: bold;">New Feature Award Application</p>
        <p v-else style="text-align: center;color: #ff6450;font-size: 28px;font-weight: bold;">{{
          entryInfo.application_type ==
            '个人' ? 'New Individual Application' : 'New Team Application' }}</p>
        <el-form :model="entryInfo" :rules="rules" ref="entryForm" label-position="right" label-width="140px"
          style="margin-top: 20px;">
          <el-form-item v-if="checkIsFeatureAwardEntry()" label="Feature Award">
            <el-input v-model="entryInfo.featue_award_title" :disabled="true" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item v-else label="Project">
            <el-input v-model="entryInfo.show_project" :disabled="true" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item label="Application Type">
            <el-input v-model="entryInfo.show_type" :disabled="true" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item label="Entry Title" prop="title">
            <el-input v-model="entryInfo.title" style="width: 80%;" @input="handleTitleInputChanged"
              placeholder="Maximum 50 characters"></el-input>
          </el-form-item>
          <el-form-item label="Entry Type" prop="entry_type">
            <el-radio v-model="entryInfo.entry_type" :disabled="checkIsFeatureAwardEntry()"
              label="story_line">Storyline</el-radio>
            <el-radio v-model="entryInfo.entry_type" :disabled="checkIsFeatureAwardEntry()"
              label="single_activity">Single
              Activity</el-radio>
          </el-form-item>
          <el-form-item label="Category" prop="category_id">
            <el-select v-model="entryInfo.category_id" placeholder="" :disabled="checkIsFeatureAwardEntry()">
              <el-option v-for="(category, index) in categories" :key="index" :label="category.name"
                :value="category._id.$id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Team Type" v-if="entryInfo.application_type == '团队'" prop="team_type">
            <el-select v-model="entryInfo.team_type" placeholder="Please select" :disabled="checkIsFeatureAwardEntry()">
              <el-option label="Student Association" value="Student Association"></el-option>
              <el-option label="Student Club" value="Student Club"></el-option>
              <el-option label="Project Team" value="Project Team"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Tags" prop="tags">
            <vue-tags-input style="font-size: 16px;" v-model="tag" :tags="tags" :max-tags="5"
              :placeholder="tags.length > 0 ? '' : tagPlaceHolder" @tags-changed="newTags => tags = newTags" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="flex items-center justify-center">
          <el-button type="primary" @click="saveNewEntry">Submit</el-button>
        </div>
      </span>
    </el-dialog>
    <el-empty description="No Data Yet" v-if="!dataList || dataList.length === 0"></el-empty>
  </div>
</template>

<script>
import {
  submitReviewEntryDate,
  getTypes,
  removeReviewEntry,
  checkRegistration,
  getReviewEntriesByUserIdV1,
  getReviewEntriesCountsByProject,
  getCurrentProjectManage,
  getStudentMemberShipExpiredDate,
  updateReviewEntry,
  removeEntryPeople
} from "../../api/eae";
import { getUserId, FORMAT_PROJECT } from "../../utils/store";
import ActivityEntry from "../../components/ActivityEntry.vue";
import FeatureAwards from "../../components/FeatureAwards.vue";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "index",
  components: {
    FeatureAwards,
    VueTagsInput,
    ActivityEntry,
  },
  data() {
    return {
      tagPlaceHolder: 'Maximum 5 tags, click "enter" for each',
      FORMAT_PROJECT,
      separators: [","],
      tag: '',
      tags: [],
      dataList: [],
      dialogVisible: false,
      alreadyEntryCounts: -1,
      currentProjectManage: {},
      entryInfo: {
        id: '',
        category_id: '',
        title: '',
        intro: '',
        user_id: getUserId(),
        open_status: 'YES',
        entry_type: '',
        show_type: '',
        application_type: '',
        team_type: '',
        tags: ''
      },
      rules: {
        title: [
          { required: true, message: 'Please input', trigger: 'blur' }
        ],
        entry_type: [
          { required: true, message: 'Please select', trigger: 'blur' }
        ],
        team_type: [
          { required: true, message: 'Please select', trigger: 'blur' }
        ],
        category_id: [
          { required: true, message: 'Please select', trigger: 'blur' }
        ],
      },
      categories: [],
      enableCreateIndividualApp: true,
      enableCreateTeamApp: true,

      str_note_1: '',
      str_note_2: '',
      membership_status: "no",

      showFeatureAwardListView: false
    };
  },
  async mounted() {
    try {
      await this.fetchData();
    } catch (error) {
      console.error('Error loading data:', error);
      // Initialize empty arrays if data loading fails
      this.dataList = [];
      this.categories = [];
      this.tags = [];
    }
  },
  methods: {

    doSubmitReviewEntryDate(item){
      submitReviewEntryDate(item._id.$id).then((res) => {
        this.$message.success("Submit Successfully");
        this.fetchData();
      });
    },

    checkEnableShowFeatureAwardButton() {
      return (getUserId() == '61cd6856a4640b946f8b456f' || getUserId() == '64b0f34ca4640b167b8b45b2' || getUserId() == '654de577a4640bc4308b45ee')
    },

    checkIsFeatureAwardEntry() {
      if (!this.entryInfo) {
        return false
      }
      let result = false
      if (this.entryInfo.feature_award_id && this.entryInfo.feature_award_id != '') {
        result = true
      }
      return result
    },

    handleApplyFeatureAward(feature_award) {
      this.createEntry(feature_award.application_type, feature_award)
    },

    formatLastCount() {
      if (this.alreadyEntryCounts == 0) {
        return 'four'
      }
      if (this.alreadyEntryCounts == 1) {
        return 'three'
      }
      if (this.alreadyEntryCounts == 2) {
        return 'two'
      }
      if (this.alreadyEntryCounts == 3) {
        return 'one'
      }
    },

    handleTitleInputChanged(value) {
      if (value) {
        this.entryInfo.title = value.charAt(0).toUpperCase() + value.slice(1);
      }
    },

    saveNewEntry() {
      let titleLength = this.entryInfo.title.length;
      if (titleLength > 50) {
        this.$message.error("Entry title characters at maximum 50");
        return;
      }
      if (this.entryInfo.tags) {
        let temp_tags = this.entryInfo.tags.split(',')
        if (temp_tags.length > 5) {
          this.$message.error('Entry tags at maximum  5 tags')
          return
        }
      }
      this.entryInfo.tags = ''
      if (this.tags.length > 0) {
        let length = this.tags.length
        for (var i = 0; i < length; i++) {
          var tag = this.tags[i].text
          this.entryInfo.tags = this.entryInfo.tags + tag
          if (i != (length - 1)) {
            this.entryInfo.tags = this.entryInfo.tags + ","
          }
        }
      }
      this.$refs['entryForm'].validate((val) => {
        if (val) {
          updateReviewEntry(this.entryInfo).then((res) => {
            let result = res.data
            if (result.code == 0) {
              this.dialogVisible = false
              this.$message.success("Submit Successful");
              this.$router.push({
                path: "entryEdit",
                query: {
                  id: result.data._id.$id,
                },
              });
            } else {
              this.$message.error(result.msg);
            }
          })
        }
      })
    },

    async createEntry(application_type, feature_award) {
      try {
        console.log('Starting createEntry');
        console.log('application_type:', application_type);
        
        this.showFeatureAwardListView = false;
        
        // Add defensive checks
        if (!this.enableCreateIndividualApp && application_type === '个人') {
          console.log('Individual applications disabled');
          return;
        }
        if (!this.enableCreateTeamApp && application_type === '团队') {
          console.log('Team applications disabled');
          return;
        }

        console.log('Time check passed');
        //查看会员
        if (this.membership_status != 'yes') {
          this.$message.error('Please pay registration')
          if (getUserId() == '61cd6856a4640b946f8b456f' || getUserId() == '64b0f34ca4640b167b8b45b2' || getUserId() == '654de577a4640bc4308b45ee') {
            this.$router.push("/home/universal-pass");
            return
          }
          this.$router.push({
            path: "/home/registration",
          });
          return
        }

        console.log('时间没过2')
        //查看已经创建个个数
        if (this.alreadyEntryCounts == -1) {//这种情况不应该出现，但是还要判断一下
          this.$message.error("No project found");
          return
        }

        if (this.alreadyEntryCounts >= 4) {
          this.$message.error("Maximum four applications per subscription cycle.");
          return;
        }
        console.log('时间没过3')
        let user_id = getUserId();
        let checkResult = await checkRegistration(user_id) //主要判断是不是会员，以及会员是否过期了
        if (checkResult.data.code != 0) {
          this.$message.error(res.data.msg);
          if (getUserId() == '61cd6856a4640b946f8b456f' || getUserId() == '64b0f34ca4640b167b8b45b2' || getUserId() == '654de577a4640bc4308b45ee') {
            this.$router.push("/home/universal-pass");
            return
          }
          this.$router.push({
            path: "/home/registration",
          });
        }
        console.log('时间没过4'+this.currentProjectManage.project_code)
        this.entryInfo = {
          id: '',
          show_project: this.currentProjectManage.project_code,
          category_id: feature_award ? feature_award.category_id : '',
          title: '',
          intro: '',
          user_id: user_id,
          open_status: 'YES',
          entry_type: feature_award ? feature_award.entry_type : '',
          application_type: application_type,
          team_type: feature_award ? feature_award.team_type : '',
          tags: '',
          feature_award_id: feature_award ? feature_award._id.$id : '',
          featue_award_title: feature_award ? feature_award.award_title : ''
        }
        console.log('时间没过5' + this.entryInfo.show_project)
        this.tags = []
        this.tag = ''
        console.log('时间没过6')
        if (this.entryInfo.application_type == '个人') {
          this.entryInfo.show_type = 'Individual Application'
        } else {
          this.entryInfo.show_type = 'Team Application'
        }
        console.log('时间没过7')
        this.dialogVisible = true
        console.log('时间没过8')
      } catch (error) {
        console.error('Error in createEntry:', error);
        this.$message.error('An error occurred while creating the entry');
      }
    },

    convertTimeToEng(time) {
      var date = new Date(time * 1000);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return this.formatMonthToEng(month) + " " + day + ", " + year;
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },

    removeEntry(item) {
      removeReviewEntry(item._id.$id).then((res) => {
        this.$message.success("Deleted Successfully");
        this.fetchData();
      });
    },

    async fetchData() {
      try {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let user_id = getUserId();

        let reiviewEntryListResult = await getReviewEntriesByUserIdV1(user_id)
        this.dataList = reiviewEntryListResult.data.data;

        let reiviewEntryCountResult = await getReviewEntriesCountsByProject(user_id)
        if (reiviewEntryCountResult.data.code == 0) {
          this.alreadyEntryCounts = reiviewEntryCountResult.data.data
        } else {
          this.alreadyEntryCounts = -1
        }
        let categoryListResult = await getTypes()
        this.categories = categoryListResult.data.data

        let currentProjectManageResult = await getCurrentProjectManage()
        this.currentProjectManage = currentProjectManageResult.data

        let entry_date = this.currentProjectManage.entry_date
        entry_date.sort(function (a, b) {
          return a.ddl_date - b.ddl_date
        })

        let currentTime = parseInt(new Date().getTime() / 1000)
        let one_day = 24 * 60 * 60 * 1000 - 1

        let indie_submission_date = -1
        let team_submission_date = -1
        for (let i = 0; i < entry_date.length; i++) {
          let item = entry_date[i]
          let ddl_date = 0
          if (getUserId() == "66bc7379a4640b966a8b4569") {//特殊处理一下
            ddl_date = parseInt((item.ddl_date + 2 * one_day) / 1000)
          } else {
            ddl_date = parseInt((item.ddl_date + one_day) / 1000)
          }
          if (item.app_type == 'indie' && indie_submission_date == -1) {
            if(currentTime < ddl_date){
              indie_submission_date = ddl_date
            }
          }

          if (item.app_type == 'team' && team_submission_date == -1) {
            if(currentTime < ddl_date){
              team_submission_date = ddl_date
            }
          }
        }

        let submission_date = new Date(indie_submission_date * 1000)
        let submission_year_end_time = parseInt(new Date(submission_date.getFullYear(), 11, 31, 23, 59, 59).getTime() / 1000)

        if (currentTime > indie_submission_date && currentTime <= submission_year_end_time) {
          this.enableCreateIndividualApp = false
        }
        if (currentTime > team_submission_date && currentTime <= submission_year_end_time) {
          this.enableCreateTeamApp = false
        }

        //这边逻辑先暂时写死，后面需要轮训获取
        
        let indieDate = this.currentProjectManage.entry_date.find(d => d.app_type === 'indie' && d.ddl_date > currentTime*1000);
        let teamDate = this.currentProjectManage.entry_date.find(d => d.app_type === 'team' && d.ddl_date > currentTime*1000);
        
        let indieDateStr = new Date(indieDate.ddl_date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric', 
          year: 'numeric'
        });
        
        let teamDateStr = new Date(teamDate.ddl_date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        });

        this.str_note_2 = `The deadlines for the upcoming review cycles are <span style="color: #ff6450;">${indieDateStr},&nbsp;for 
              individual applications</span>
          and <span style="color: #ff6450;"> ${teamDateStr} ,&nbsp;for team applications</span>.`

        // if (indie_submission_date < team_submission_date) {
        //   this.str_note_2 = `2. The deadlines for the upcoming review cycles are <span style="color: #ff6450;">${this.convertTimeToEng(indie_submission_date)},&nbsp;for
        //       individual applications</span>
        //     and <span style="color: #ff6450;"> ${this.convertTimeToEng(team_submission_date)} ,&nbsp;for team applications</span>.`
        // } else {
        //   this.str_note_2 = `The deadlines for the upcoming review cycles are <span style="color: #ff6450;">${this.convertTimeToEng(team_submission_date)},&nbsp;for
        //       team applications</span>
        //     and <span style="color: #ff6450;"> ${this.convertTimeToEng(indie_submission_date)} ,&nbsp;for individual applications</span>.`
        // }

        let studentMembershipResult = await getStudentMemberShipExpiredDate({ student_id: user_id })
        let studentMembershipExpiredTime = studentMembershipResult.data.data

        if (studentMembershipExpiredTime == -1) {
          this.alreadyEntryCounts = 4
          this.membership_status = 'no'
          // this.str_note_1 = 'You need to purchase EAE Awards subscription for applications.'
        } else {
          let current_time = parseInt(new Date().getTime() / 1000)
          let membership_date = this.convertTimeToEng(studentMembershipExpiredTime)
          if (studentMembershipExpiredTime <= current_time) {
            this.membership_status = 'expired'
            // this.str_note_1 = `Your EAE Awards subscription has expired on ${membership_date}.`
          } else {
            this.membership_status = 'yes'
            if (this.alreadyEntryCounts >= 4) {
              // this.str_note_1 = `You have reached the maximum limit of 4 applications that can be created until <span style="color:#ff6450 ;">${membership_date}</span>.`
            } else {
              // this.str_note_1 = `1. You can create ${this.formatLastCount()} more ${this.alreadyEntryCounts >= 3 ? 'application' : 'applications'} by <span style="color:#ff6450 ;">${membership_date}</span>.</p>`
            }
          }
        }
        loading.close();
      } catch (error) {
        console.error('Error in fetchData:', error);
        // Set default values if there's an error
        this.dataList = [];
        this.categories = [];
        this.alreadyEntryCounts = -1;
      }
    },
    editEntry(row) {
      //判断 有没有 报名缴费
      let user_id = getUserId();
      checkRegistration(user_id).then((res) => {
        if (res.data.code == 0) {
          this.$router.push({
            path: "entryEdit",
            query: {
              id: row._id.$id,
            },
          });
        } else {
          this.$message.error(res.data.msg);
          if (getUserId() == '61cd6856a4640b946f8b456f' || getUserId() == '64b0f34ca4640b167b8b45b2' || getUserId() == '654de577a4640bc4308b45ee') {
            this.$router.push("/home/universal-pass");
            return
          }
          this.$router.push({
            path: "/home/registration",
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.showFeatureAwardListView = false
    },

    showStandardsDialog(row) {
      if (!row.activity_option_id) {
        this.$message.warning("No data");
        return;
      }
      this.$router.push({
        path: "standards",
        query: {
          id: row.activity_option_id,
          entryId: row._id.$id,
        },
      });
    },

    handleQuitEntry(params) {
      removeEntryPeople(params.entry_id, params.entry_people_id).then((res) => {
        this.$message.success('Quit Team Entry Successful')
        this.fetchData()
      })
    }
  },
};
</script>

<style>
.ti-tag {
  background-color: #ff6450 !important;
  /* 您想要的颜色 */
}

.ti-input {
  border-radius: 5px;
}
</style>

<style lang="scss" scoped>
.btn-add-entry {
  text-align: center;
  width: 200px;
  height: 80px;
  background: #ff6450;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.custom-btn {
  background: #ff6450;
  border-radius: 5px;
  padding: 6px 10px;
  color: white;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
}

.entry-type {
  height: 112px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  align-items: center;

  &.selected {
    border: 2px solid #ff6450;
    background: #fff8f7;
  }

  .cover {
    width: 108px;
    height: 108px;
    // border-radius: 5px;
  }

  .desc {
    height: 52px;
    overflow: hidden;
    font-weight: bold;
    color: #888888;
    word-break: keep-all;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 18px;
  }
}
</style>
